import React from "react";
import Footer from "./footer";
import Header from "./header";
import SubHeader from "./subHeader";

const Layout = ({
  children,
  station,
  h1Text,
  useAuth0DefaultRedirect = false
}) => {
  return (
    <div className="mx-auto bg-white">
      <Header useAuth0DefaultRedirect={useAuth0DefaultRedirect}></Header>
      <SubHeader station={station} h1Text={h1Text}></SubHeader>
      <main id="main" className="aria-target" tabIndex="-1">
        {children}
      </main>
      <Footer color="bg-primary-700" station={station}></Footer>
    </div>
  );
};

export default Layout;
